import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { MusicCatalogList, MusicCatalogItem } from '../components/MusicCatalog';
import VerticalChunk from "../components/VerticalChunk";

import data_MG_1247 from "../images/photos/pressRes/_MG_1247.jpg";
import data_MG_1300 from "../images/photos/pressRes/_MG_1300.jpg";
import dataCAT3717 from "../images/photos/pressRes/_CAT3717.jpg";
import dataCAT3918 from "../images/photos/pressRes/_CAT3918.jpg";
import dataIMGP1843 from "../images/photos/pressRes/IMGP1843.jpg";
import dataIMGP1846 from "../images/photos/pressRes/IMGP1846.jpg";
import dataJesse_Rivest_Toadstool_007 from "../images/photos/pressRes/Jesse_Rivest_Toadstool_007.jpg";
import dataJesse_Rivest_Toadstool_021 from "../images/photos/pressRes/Jesse_Rivest_Toadstool_021.jpg";
import dataRebecca1 from "../images/photos/pressRes/rebecca1.jpg";
import dataRebecca2 from "../images/photos/pressRes/rebecca2.jpg";
import data9662copy from "../images/photos/pressRes/9662copy.jpg";
import data9692copy from "../images/photos/pressRes/9692copy.jpg";
import dataFrancis from "../images/photos/pressRes/francis.jpg";
import dataJesseinrocky from "../images/photos/pressRes/jesseinrocky.jpg";
import dataJaneEixao1 from "../images/photos/pressRes/P1020486_developed.jpg";
import dataJaneEixao2 from "../images/photos/pressRes/P1020532_developed.jpg";
import dataMurilo5 from "../images/photos/pressRes/Jesse-high-5.jpg";
import dataMurilo7 from "../images/photos/pressRes/Jesse-high-7.jpg";

const width = 400;

const PressPage = () => {
	return (
		<Layout>
			<Seo title="Press Kit" />
			<h2>Press Kit</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/_MG_1300.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>

			<h3>Jesse Rivest EPK</h3>
			<p>
				This is as an electronic press kit; this page is the root of it;
				use your browser's back button to return here. <span className="italic">Press photos are further down the page</span>.

			</p>
			<p className="card">
				<span className="card">
					Considering booking me or collaborating? Please start at <Link to="/video/">Videos</Link>,
					then <Link to="/music/">Music</Link> and <Link to="/events/">Tour dates</Link> (history),
					perhaps my <Link to="/bio/">bio</Link>,
					and finally <Link to="/contact/">contact me</Link>.
				</span>
			</p>
			<p>
				Below you will find materials for promoting our event, joint venture, or project.
			</p>
			<p>
				If you wish to <Link to="/support/">help</Link> with material here—notable reviews or praise,
				influencers/bloggers/playlisters, new photographs or videos, posters, etc.,
				then please do <Link to="/contact/">contact me</Link>.
			</p>

			<h4>Description</h4>
			<p>
				Jesse Rivest is an indie-folk, contemporary singer-songwriter and guitarist.<br />
				His hometown is Kelowna, BC, Canada.<br />
				He got his start in Calgary, AB, in the open mic scene in 2004.<br />
				He mostly performs solo but has on occasion formed duos, trios, and bands.<br />
				He has produced most of his music himself, including 17 separate releases (singles, EPs, and LPs).<br />
				He has performed and lived in Canada, New Zealand, Australia, Brazil, and Argentina.<br />
				He is split-based between Kelowna and Brasilia, the capital of Brazil.
			</p>

			<h4>Praise</h4>
			<blockquote cite="//archive.fo/DQrQ3">
				<p>
					"Enjoyed watching his mastery of his guitar and how supportive he was of his
					fellow musicians."
				</p>
				<footer className="p">
					—Audience member, <cite><a href="//archive.fo/DQrQ3">Side Door</a></cite>
				</footer>
			</blockquote>
			<blockquote cite="//archive.ph/dnS54">
				<p>
					"Jesse is wonderful storyteller who weaves multiple musical traditions with just
					his guitar and voice."
				</p>
				<footer className="p">
					—Emmanuel United Church, <cite><a href="//archive.ph/dnS54">Side Door</a></cite>
				</footer>
			</blockquote>

			<h4>Reviews</h4>
			<p>
				There is nothing current or relevant at this time.
			</p>

			<h4>Noteworthy</h4>
			<p>
				On April 4, 2022, <span className="italic">Wonderful Words</span> (co-write and co-primary artist) was added to Spotify's editorial playlist, <span className="bold">Vocal Jazz</span>,
				where it stayed for 11 months and neared 18K streams.
			</p>

			<h4>Publicist</h4>
			<p>
				If we need a marketing publicist, we can consider <a href="//auteurresearch.com/artists/jesse-rivest/">Auteur Research</a> as I have worked with them before.
			</p>

			<h4>Hi-Res Images</h4>
			<VerticalChunk>
				<MusicCatalogList>
					<MusicCatalogItem
					year={2021}
					title="Murilo Fabrino"
					width={width}
					>
						<a href={dataMurilo5}>
							<StaticImage
							src="../images/photos/pressRes/Jesse-high-5.jpg"
							alt="Jesse Rivest in Brasilia, by Murilo Fabrino, 2021"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2021}
					title="Murilo Fabrino"
					width={width}
					>
						<a href={dataMurilo7}>
							<StaticImage
							src="../images/photos/pressRes/Jesse-high-7.jpg"
							alt="Jesse Rivest in Brasilia, by Murilo Fabrino, 2021"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2019}
					title="Jesse Rivest & Jane Lino"
					width={width}
					>
						<a href={dataJaneEixao1}>
							<StaticImage
							src="../images/photos/pressRes/P1020486_developed.jpg"
							alt="Jesse Rivest in Brasilia, by Jesse Rivest and Jane Lino, 2019"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2019}
					title="Jesse Rivest & Jane Lino"
					width={width}
					>
						<a href={dataJaneEixao2}>
							<StaticImage
							src="../images/photos/pressRes/P1020532_developed.jpg"
							alt="Jesse Rivest in Brasilia, by Jesse Rivest and Jane Lino, 2019"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2016}
					title="Raquel Pellicano"
					width={width}
					>
						<a href={data_MG_1247}>
							<StaticImage
							src="../images/photos/pressRes/_MG_1247.jpg"
							alt="Jesse Rivest in Brasilia, by Raquel Pellicano, 2016"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2016}
					title="Raquel Pellicano"
					width={width}
					>
						<a href={data_MG_1300}>
							<StaticImage
							src="../images/photos/pressRes/_MG_1300.jpg"
							alt="Jesse Rivest in Brasilia, by Raquel Pellicano, 2016"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2013}
					title="James Hirata - Catalyst Photo"
					width={width}
					>
						<a href={dataCAT3717}>
							<StaticImage
							src="../images/photos/pressRes/_CAT3717.jpg"
							alt="Jesse Rivest on stage at Daddy O's, by James Hirata, 2013"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2013}
					title="James Hirata - Catalyst Photo"
					width={width}
					>
						<a href={dataCAT3918}>
							<StaticImage
							src="../images/photos/pressRes/_CAT3918.jpg"
							alt="Jesse Rivest on stage at Daddy O's, by James Hirata, 2013"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2013}
					title="Donald Laing"
					width={width}
					>
						<a href={dataIMGP1843}>
							<StaticImage
							src="../images/photos/pressRes/IMGP1843.jpg"
							alt="Jesse Rivest with Brendan at Daddy O's, by Donald Laing, 2013"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2013}
					title="Donald Laing"
					width={width}
					>
						<a href={dataIMGP1846}>
							<StaticImage
							src="../images/photos/pressRes/IMGP1846.jpg"
							alt="Jesse Rivest with Brendan and Peter at Daddy O's, by Donald Laing, 2013"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2010}
					title="Aaron Burgess"
					width={width}
					>
						<a href={dataJesse_Rivest_Toadstool_007}>
							<StaticImage
							src="../images/photos/pressRes/Jesse_Rivest_Toadstool_007.jpg"
							alt="Jesse Rivest with a mushroom, by Aaron Burgess, 2010"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2010}
					title="Aaron Burgess"
					width={width}
					>
						<a href={dataJesse_Rivest_Toadstool_021}>
							<StaticImage
							src="../images/photos/pressRes/Jesse_Rivest_Toadstool_021.jpg"
							alt="Jesse Rivest with a mushroom, Aaron Burgess, 2010"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2008}
					title="Rebecca Bain"
					width={width}
					>
						<a href={dataRebecca1}>
							<StaticImage
							src="../images/photos/pressRes/rebecca1.jpg"
							alt="Jesse Rivest in Island Bay, by Rebecca Bain, 2008"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2008}
					title="Rebecca Bain"
					width={width}
					>
						<a href={dataRebecca2}>
							<StaticImage
							src="../images/photos/pressRes/rebecca2.jpg"
							alt="Jesse Rivest in Island Bay, by Rebecca Bain, 2008"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2006}
					title="Jessica Balfour"
					width={width}
					>
						<a href={data9662copy}>
							<StaticImage
							src="../images/photos/pressRes/9662copy.jpg"
							alt="Jesse Rivest in Kelowna, by Jessica Balfour, 2006"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2006}
					title="Jessica Balfour"
					width={width}
					>
						<a href={data9692copy}>
							<StaticImage
							src="../images/photos/pressRes/9692copy.jpg"
							alt="Jesse Rivest in Kelowna, by Jessica Balfour, 2006"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2006}
					title="Francis A. Willey"
					width={width}
					>
						<a href={dataFrancis}>
							<StaticImage
							src="../images/photos/pressRes/francis.jpg"
							alt="Jesse Rivest in Francis A. Willey's studio, 2006"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
					<MusicCatalogItem
					year={2005}
					title="Jana Graenz"
					width={width}
					>
						<a href={dataJesseinrocky}>
							<StaticImage
							src="../images/photos/pressRes/jesseinrocky.jpg"
							alt="Jesse Rivest in Rockhampton, by Jana Graenz, 2005"
							placeholder="blurred"
							layout="constrained"
							imgClassName="img--bordered-small"
							width={width}
							/>
						</a>
					</MusicCatalogItem>
				</MusicCatalogList>
			</VerticalChunk>
		</Layout>
	);
}

export default PressPage;
