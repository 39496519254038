import * as React from "react";
import { Link } from "gatsby";
import '../styles/components/_musiccatalog.scss';

const months = ['use 1 - 12', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const MusicCatalogList = (props) => (
	<div className="MusicCatalogList p">
		{props.children}
	</div>
);

const MusicCatalogItem = ({children: image, title, year, month, category, catnum, width, url}) => (
	<div className="MusicCatalogItem" style={{width: width + 'px'}}>
		{image}
		<CatalogItemTitle url={url}>
			{title}
		</CatalogItemTitle>
		<CatalogItemYear>
			{months[month]} {year}
		</CatalogItemYear>
		<CatalogItemCategory>
			{category}
		</CatalogItemCategory>
		<CatalogItemNumber>
			{catnum}
		</CatalogItemNumber>
	</div>
);

const CatalogItemTitle = (props) => (
	<div className="CatalogItemTitle">
		<Link to={props.url}>{props.children}</Link>
	</div>
);

const CatalogItemYear = (props) => (
	<div className="CatalogItemYear">
		{props.children}
	</div>
);

const CatalogItemCategory = (props) => (
	<div className="CatalogItemCategory">
		{props.children}
	</div>
);
const CatalogItemNumber = (props) => (
	<div className="CatalogItemNumber">
		{props.children}
	</div>
);

export {MusicCatalogList, MusicCatalogItem, CatalogItemTitle, CatalogItemYear, CatalogItemCategory, CatalogItemNumber};